import React from "react";
import LayoutWrapper from "../../component/LayoutWrapper/Index";
import BlogsPosts from "../../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../../component/Blog/ShareLinks";
import AuthorAvatar from "../../component/Blog/AuthorAvatar";
import CommonParagraph from "../../component/Blog/CommonParagraph";
import Heading from "../../component/Blog/Heading";
import BlogLink from "../../component/Blog/BlogLink";
import { blogList } from "../../data/blogs";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ContactSlideoutLink from "../../component/Blog/ContactSlideoutLink";

const BlogPost = () => {
  const blogData = blogList.filter(
    (blog) => blog.url == "/rancho-penasquitos-real-estate-market-overview"
  );

  return (
    <LayoutWrapper
      title={blogData[0].title}
      desc={blogData[0].metaDescription}
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#020101] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              {blogData[0].title}
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <a
                  href={blogData[0].authorUrl && blogData[0].authorUrl}
                  className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]"
                >
                  By {blogData[0].author}
                </a>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">
                  {blogData[0].date}
                </p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../../images/The Selby Team_Rancho Peñasquitos Real Estate Market Overview.jpg"
              alt="blogpost"
            />
            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <CommonParagraph>
                If you’re looking to buy or sell in Southern California and are wondering what the
                real estate market is like in the Rancho Penasquitos area, you’ve come to the right
                place! Here, we’ll review market performance, housing options, growth and investment
                potential, and the community in general so you can make an informed real estate
                decision.
              </CommonParagraph>
              <Heading type="h2">Recent Market Performance</Heading>
              <CommonParagraph>
                Based on information from the multiple listing service (MLS), Rancho Penasquitos is
                a seller’s market. That means homes in the area tend to sell faster and for more
                money than those in other markets.
              </CommonParagraph>
              <CommonParagraph>
                If you want to{" "}
                <BlogLink
                  url="https://selbysellssd.com/checklist-for-selling-your-san-diego-home-quickly/"
                  text="sell your Rancho Penasquitos home"
                />
                , now may be the perfect time. The median home sold price went up 8% from last year
                to an average of about $1.2MM. 1, 3, and 5 or more bedroom homes saw the largest
                market increase, around 5.7%, 5.2%, and 7.6%, respectively.
              </CommonParagraph>
              <CommonParagraph>
                These increases may have been the push that some needed to put their house up for
                sale, as more homeowners were looking to sell in March 2024 than just one month
                prior. February saw 46 Rancho Penasquitos homes for sale, while 58 were in March. If
                this trend continues, the supply increases could tip the scale from a seller’s
                market to a buyer’s market.
              </CommonParagraph>
              <Heading type="h2">Housing Options</Heading>
              <CommonParagraph>
                Cozy residential, charming condo, sprawling estate; it’s all available in Rancho
                Penasquitos!
              </CommonParagraph>
              <CommonParagraph>
                This suburban community has many different{" "}
                <BlogLink
                  url="https://selbysellssd.com/san-diego-communities/rancho-penasquitos-homes-for-sale"
                  text="housing options"
                />
                , making it perfect for sellers and buyers. Sellers can feel confident that there’s
                a market for the type of home they have, and buyers typically find a range of
                options that suit their specific needs and lifestyle.
              </CommonParagraph>
              <CommonParagraph>
                The diverse range of options also means diverse prices, with many falling well below
                the median price point—another plus for those looking to buy.
              </CommonParagraph>
              <Heading type="h2">Growth & Investment Potential</Heading>
              <CommonParagraph>
                With the upward appreciation trend in home values and strong buyer demand, Rancho
                Penasquitos may be a serious contender if you invest in real estate.
              </CommonParagraph>
              <CommonParagraph>
                With a solid foundation, track record of long-term success, and ongoing initiatives
                to enhance infrastructure, it’s easy to see why investors are attracted to the area.
                Rancho Penasquitos is worth exploring if you want to capitalize on a booming real
                estate market.
              </CommonParagraph>
              <Heading type="h2">Rancho Penasquitos Community</Heading>
              <CommonParagraph>
                While it may be a seller’s market currently, Rancho Penasquitos is most definitely a
                buyer’s paradise. There’s a vibrant sense of community and several amenities,
                including parks, shopping centers, and local events.
              </CommonParagraph>
              <CommonParagraph>
                With top-rated schools, those with little ones can rest assured their children are
                getting a world-class education. There are also several extracurricular activities,
                making this an ideal place to raise a family.
              </CommonParagraph>
              <CommonParagraph>
                Whether exploring the outdoors, enjoying the nightlife, or participating in a
                community-held event, there’s something for everyone.
              </CommonParagraph>
              <Heading type="h2">Buy or Sell With Help From The Selby Team</Heading>
              <CommonParagraph>
                As Southern California real estate experts, The Selby Team is here to help you start
                your next chapter in life, whether that means{" "}
                <BlogLink url="https://selbysellssd.com/sell-your-san-diego-home" text="selling" />{" "}
                or <BlogLink url="https://selbysellssd.com/buy-house-san-diego" text="buying" /> a
                Rancho Penasquitos property. <ContactSlideoutLink text="Connect with us today" /> to
                get started!
              </CommonParagraph>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
